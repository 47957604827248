<template>
  <div class="home">
  <div class="header">
    <!-- <img src="../assets/headright@2x.png" alt="" srcset=""> -->
     <span style="position:absolute;top: 3px;
    left: 787px;
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #FAC505;">对党忠诚  服务人民  执法公证  纪律严明</span>
     <span>
      <img src="../assets/cs48@2x.png" alt="" style="margin-left: -188px;margin-top: 8px;vertical-align:middle">
      <span style="font-size: 46px;font-family: Source Han Sans CN;font-weight: 400;background: linear-gradient(0deg, #1765FF 0%, #1765FF 0%, #49D9FE 54.931640625%, #8FEFFF 80.322265625%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;margin-left:9px;position:relative;top:15px">局长信箱即接即办</span></span>
     <span class="time"><span style="font-size: 24px;font-family: Source Han Sans CN;font-weight: bold;color: rgba(57, 214, 254, 1);" id="timer"></span>&nbsp;&nbsp;&nbsp;<span style="font-size:22px">{{ newDate (new Date())}}</span> &nbsp;&nbsp;&nbsp;<span style="font-size:22px">{{ getweek() }}</span></span>
  </div>
  <div class="main">
    <div class="left_one">
         <span style="position:relative;top: 3px;left: 3px;">
            <span class="fontSize" style="position:absolute;left:50%;transform:translate(-63%);">今日执班</span>
            <img src="../assets/top@2x.png" alt="" style="width:400px;44px;margin-top:2px">
         </span>
         <!-- <img src="../assets/one@2x.png" alt="" srcset=""> -->
         <div style="line-height:68px">
           <div v-for="(item,index) in leftList" :key="index" style="font-size:24px;display:flex;justify-content:space-around;margin:0 10px;align-items:center">
             <img :src="item.icon" alt="" style="vertical-algin:middle">
             <span style="font-family: Microsoft YaHei;font-weight: 400;color: #26BEFE;">{{item.title }}</span>
             <span style="color:white;" v-for="(temp,ind) in item.names" :key="ind">{{ temp }}</span>
           </div>
         </div>
    </div>
    <div class="left_two">
      <div class="left_two_msg" style="text-align:center">
        <span class="fontSize" style="position:absolute;left:50%;transform:translate(-60%);">当日信件</span>
         <img src="../assets/top@2x.png" style="margin-top:2px" alt="" srcset="">
          <div style="display:flex;justify-content:space-around;padding:6px;margin-top:8px">
             <div v-for="(item,index) in dataLeftTop" :key="index" style="width: 174px;height: 108px;background: #010A53;border-radius: 5px;display:flex;;justify-content:space-around;">
               <div style="position:relative;top:8px"><img :src="item.icon" alt="" srcset=""></div>
                <div>
                  <div style="font-size: 46px;font-family: Source Han Sans CN;font-weight: bold;font-style: italic;color: #4AFFFF;">{{item.num}}</div>
                  <div style="font-size: 24px;font-family: Source Han Sans CN;font-weight: 400;color: #FFFFFF;">{{item.title}}</div>
                </div>
             </div>
          </div>
      </div>
      <div class="left_two_real" style="text-align:center">
          <span  class="fontSize" style="position:absolute;left:50%;transform:translate(-60%);">实时信件</span>
          <img src="../assets/top@2x.png" style="margin-top:2px" alt="" srcset="">
          <div style="margin-left:10px;">
            <div v-for="(item,index) in dataLeftBottom" :key="index" style="border-bottom: 1px dashed #00FFFF;margin-bottom: 10px;
              padding-right: 20px;
              margin-left: 25px;">
              <div style="display:flex;justify-content:space-between;align-items:center">
                  <span style="font-size: 16px;font-family: Source Han Sans CN;font-weight: bold;color: #FFFFFF;">{{ item.time }}</span>
                  <div>
                       <span style="display:inline-block;font-size: 16px;font-family: Source Han Sans CN;font-weight: 400;color: #FFFFFF;width: 112px;
                        height: 32px;background: #01046F;line-height:32px;margin-right:15px">{{ item.title }}</span>
                       <span style="position:relative;top:6px"><img src="../assets/btn_two.png" alt="" srcset="" ><span style="position:absolute;left:50%;transform:translate(-50%);color:red">{{ item.type}}</span></span>
                  </div>
              </div>
              <div style="font-size: 16px;font-family: Source Han Sans CN;font-weight: 400;color: #FFFFFF;text-align:left;line-height:34px">
                {{ item.content }}
              </div>
              <div style="display:flex;justify-content:space-between;align-items:center;margin:10px 0">
                <div style="font-size: 18px;font-family: Source Han Sans CN;font-weight: 500;color: #00E1F9;">
                   <span><img src="../assets/user_icon.png" alt="" srcset="" style="vertical-align:middle"><span style="margin:0 14px">{{item.name }}</span></span>
                   <span><img src="../assets/phone_icon.png" alt="" srcset="" style="vertical-align:middle" ><span  style="margin:0 8px">{{item.phone }}</span></span>
                </div>
                <span  :style="{ background: item.status == 1 ?'green':'red'}" style="border-radius: 10px;display:inline-block;width:102px;height:30px;line-height:30px">{{item.status==1?'已受理':'未受理' }}</span>
              </div>
            </div>
          </div>

      </div>
    </div>
    <div class="center">
        <div class="center_content">
          <div class="center_top">
            <div style="margin-top:18px">
                <el-date-picker
                    style="background:transparent;width:348px;height:40px;margin-right:5px"
                    v-model="value1"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"

                    value-format="yyyy-MM-dd"
                    format="yyyy-MM-dd"
                    @change="changDate"
                    >
                  </el-date-picker>
                   <el-select v-model="value0"  placeholder="全部" @change="selectOpt">
                    <el-option
                      v-for="item in options0"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
            </div>
              <div>
                <span class="fontSize" style="position:absolute;left:50%;top:0px;transform:translate(-68%);font-size: 26px;">单位办件排名统计</span>
              <img src="../assets/top@2x.png" style="width: 422px;height: 42px;margin-left: 20px" alt="">
              </div>
                  <div  style="margin-top:18px">
                     <el-select v-model="valueOpt1" placeholder="全部"  @change="selectOpt1">
                    <el-option

                      v-for="item in options1"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                     <el-select v-model="value2" placeholder="月" style=";margin:0 5px"  @change="selectOpt2">
                    <el-option
                      v-for="item in options2"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                     <el-select v-model="value3" placeholder="全部"  @change="selectOpt3" style="margin-right:8px">
                    <el-option
                      v-for="item in options3"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
              </div>

          </div>
          <div style="display:flex;justify-content:space-around;">
            <!-- <div class="center-left"> -->
              <div style="height: 244px;width:45%;margin-top:20px;" id="echart1"></div>
          <!-- </div> -->
          <!-- <div class="center-right"> -->
              <div style="height: 344px;width:50%" id="echart2"></div>
          <!-- </div> -->
          </div>

        </div>
        <div class="center_content_center" style="margin:6px 0">
            <div  style="        display: flex;
                  justify-content: space-around;">
               <div style="margin-top:18px">

                     <el-select v-model="value" placeholder="全部"  @change="resolveSituation">
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
              </div>
                 <span  class="fontSize" style="position:absolute;left:55%;transform:translate(-48%);top:5px">重复件化解情况</span>
                  <img src="../assets/top@2x.png" alt="" style="margin-top:2px">
                   <div style="margin-top:18px">
              </div>
             </div>
            <div>
                 <div style="height:244px;width:100%" id="echart3"></div>
            </div>
        </div>
        <div class="center_content_center">
              <div  style="display: flex;
                  justify-content: space-around;">
                 <div style="margin-top:18px">
                       <el-select v-model="value" placeholder="分局"   @change="resolveSit">
                      <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                </div>
                   <span  class="fontSize" style="position:absolute;left:55%;transform:translate(-50%);top:6px">重点督办件</span>
                    <img src="../assets/top@2x.png" alt="" style="margin-top:2px">
                     <div style="margin-top:18px"></div>
               </div>
              <div>
                   <div style="height:244px;width:100%" id="echart4"></div>
              </div>
          </div>
    </div>
    <div class="right">
        <div class="right_top">
          <div>
             <span class="fontSize" style="position:absolute;left:50%;transform:translate(-55%);">信件来源占比</span>
             <img src="../assets/top@2x.png" alt="" style="margin-top:2px">
          </div>
          <div style="margin-top:18px">
                  <el-date-picker
                      style="background:transparent;width:348px;height:40px;margin-right:5px"
                      v-model="value1"
                             type="daterange"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"

                      value-format="yyyy-MM-dd"
                      format="yyyy-MM-dd"
                      @change="changeTime"
                      >
                    </el-date-picker>
                    <span style="color:rgba(1, 176, 255, 1);margin-left:25px;cursor:pointer" @click="changeDate">本年   |   本季   |   本月   |   本周   |   本日</span>
          <div>
          </div>
               <div style="display:flex;justify-content:space-around;">
                <div id="echart5" style="width:60%;height:300px;"></div>
                <div style="height: 244px;width:40%" id="echart6"></div>
            </div>
          </div>
        </div>
        <div class="right_center"  style="margin:4px 0">
           <div>
            <span class="fontSize" style="position:absolute;left:50%;transform:translate(-55%);top:0px;font-size:27px">信件分类趋势分析</span>
            <img src="../assets/top@2x.png" alt="" style="margin-top:2px">
            </div>
            <div style="display:flex;;justify-content:space-around;margin-top:10px">
              <el-date-picker
                      style="background:transparent;width:348px;height:40px;margin-right:5px"
                      v-model="value1"
                      type="daterange"
                      range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                      @change="changeList"
                      >
                    </el-date-picker>
                     <el-select v-model="value" placeholder="全部" class="selts" @change="changeSelts">
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                     <el-select v-model="value4" placeholder="全部"  class="selts"  @change="changeSeltss">
                    <el-option
                      v-for="item in options3"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                     <!-- <el-select v-model="value" placeholder="全部" class="selt"  @change="changeSeltss">
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select> -->
                     <el-select v-model="valueSelt" placeholder="月" class="selt" @change="changeSeltsss">
                    <el-option
                      v-for="item in options2"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
            </div>
            <div id="echart7" style="width:100%;height:230px;position:relative;top:-10"></div>
        </div>
        <div class="right_bottom">
              <div>
                <span class="fontSize" style="position:absolute;left:50%;transform:translate(-55%);">多源来信比对</span>
                <img src="../assets/top@2x.png" alt="" style="margin-top:2px">
              </div>
              <div style="display:flex;justify-content:space-around;">
                  <div style="height: 200px;width:50%;margin-left:30px" id="echart8"></div>
                  <div style="height: 244px;width:40%" id="echart9"></div>
              </div>
        </div>
    </div>
    </div>
  </div>

</template>

<script>
/* eslint-disable */
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import * as echarts from 'echarts';
import axios from 'axios';
export default {
  name: 'HomeView',
  components: {
    // HelloWorld
  },
  data(){
    return {
      value4:'',
      //  pickerOptions2: {
      //   shortcuts: [{
      //     text: '最近一周',
      //     onClick(picker) {
      //       const end = new Date()
      //       const start = new Date()
      //       start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
      //       picker.$emit('pick', [start, end])
      //     }
      //   }, {
      //     text: '最近一个月',
      //     onClick(picker) {
      //       const end = new Date()
      //       const start = new Date()
      //       start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
      //       picker.$emit('pick', [start, end])
      //     }
      //   }, {
      //     text: '最近三个月',
      //     onClick(picker) {
      //       const end = new Date()
      //       const start = new Date()
      //       start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
      //       picker.$emit('pick', [start, end])
      //     }
      //   }]
      // },
      value0:'',
      valueOpt1:'',
      valueSelt:'',
      options0: [
        {

          value: '全部',
          label: '全部'
        }, {

        value: '芙蓉分局',
        label: '芙蓉分局'
      }, {
        value: '天心分局',
        label: '天心分局'
      }, {
        value: '岳麓分局',
        label: '岳麓分局'
      }, {
        value: '开福分局',
        label: '开福分局'
      }, {
        value: '雨花分局',
        label: '雨花分局'
      },
       {
        value: '高新分局',
        label: '高新分局'
      },
      //  {
      //   value: '望城分局',
      //   label: '望城分局'
      // }, {
      //   value: '长沙县局',
      //   label: '长沙县局'
      // }, {
      //   value: '浏阳市局',
      //   label: '浏阳市局'
      // }, {
      //   value: '宁乡市局',
      //   label: '宁乡市局'
      // }, {
      //   value: '交警支队',
      //   label: '交警支队'
      // }, {
      //   value: '督察支队',
      //   label: '督察支队'
      // },



      ],
      options: [{
        value: '0',
        label: '全部'
      }, {
        value: '1',
        label: '芙蓉分局'
      }, {
        value: '2',
        label: '天心分局'
      }, {
        value: '3',
        label: '岳麓分局'
      }, {
        value: '4',
        label: '开福分局'
      }, {
        value: '5',
        label: '雨花分局'
      }, {
        value: '6',
        label: '高新分局'
      }, {
        value: '7',
        label: '望城分局'
      }, {
        value: '8',
        label: '长沙县局'
      }, {
        value: '9',
        label: '浏阳市局'
      }, {
        value: '10',
        label: '宁乡市局'
      }, {
        value: '11',
        label: '交警支队'
      }, {
        value: '12',
        label: '督察支队'
      },



      ],

      options1: [{
        value: '全部',
        label: '全部'
      },{
        value: '芙蓉分局',
        label: '芙蓉分局'
      }, {
        value: '天心分局',
        label: '天心分局'
      }, {
        value: '岳麓分局',
        label: '岳麓分局'
      }, {
        value: '开福分局',
        label: '开福分局'
      }, {
        value: '雨花分局',
        label: '雨花分局'
      }, {
        value: '高新分局',
        label: '高新分局'
      }, {
        value: '望城分局',
        label: '望城分局'
      }, {
        value: '长沙县局',
        label: '长沙县局'
      }, {
        value: '浏阳市局',
        label: '浏阳市局'
      }, {
        value: '宁乡市局',
        label: '宁乡市局'
      }, {
        value: '交警支队',
        label: '交警支队'
      }, {
        value: '督察支队',
        label: '督察支队'
      },



      ],
      value1:'',
      options2: [
        {
        value: '1',
        label: '年'
       },
        {
        value: '2',
        label: '月'
      },
      {
        value: '3',
        label: '日'
      },
      ],
      value2:'1',
      options3: [{
        value: '0',
        label: '全部'
      }, {
        value: '1',
        label: '咨询求助类'
      }, {
        value: '投诉类',
        label: '投诉类'
      }, {
        value: '感谢类',
        label: '感谢类'
      }, {
        value: '涉众类',
        label: '涉众类'
      }, {
        value: '举报类',
        label: '举报类'
      }, {
        value: '建议类',
        label: '建议类'
      }
      ],
      value3:'',
      value: '',
      value1: [],
      leftList: [{
        icon:require('../assets/left_icon.png'),
        "title": "芙蓉分局",
        "names": ["李警官", "吴警官"]
      },
      {
        icon: require('../assets/left_icon.png'),
        "title": "天心分局",
        "names": ["吴警官", "李警官"]
      },
      {
        icon: require('../assets/left_icon.png'),
        "title": "岳麓分局",
        "names": ["李警官", "吴警官"]
      }
      ,
      {
        icon: require('../assets/left_icon.png'),
        "title": "开福分局",
        "names": ["李警官", "吴警官"]
      }
      ,
      {
        icon: require('../assets/left_icon.png'),
        "title": "雨花分局",
        "names": ["李警官", "吴警官"]
      }
      ,
      {
        icon: require('../assets/left_icon.png'),
        "title": "高新分局",
        "names": ["李警官", "吴警官"]
      },
      {
        icon: require('../assets/left_icon.png'),
        "title": "望城分局",
        "names": ["李警官", "吴警官"]
      },
      {
        icon: require('../assets/left_icon.png'),
        "title": "长沙县局",
        "names": ["李警官", "吴警官"]
      },
      {
        icon: require('../assets/left_icon.png'),
        "title": "浏阳市局",
        "names": ["李警官", "吴警官"]
      },
      {
        icon: require('../assets/left_icon.png'),
        "title": "宁乡市局",
        "names": ["李警官", "吴警官"]
      },
      {
        icon: require('../assets/left_icon.png'),
        "title": "交警支队",
        "names": ["李警官", "吴警官"]
      },
      {
        icon: require('../assets/left_icon.png'),
        "title": "督察支队",
        "names": ["李警官", "吴警官"]
      },

      ],
      dataLeftTop: [{
        width:16,
        height:18,
        icon:require('../assets/policeman-full.png'),
        num: 20,
        title: "局长信箱"
      }, {
        width: 22,
        height: 14,
        icon: require('../assets/duoren.png'),
        num: 55,
        title: "12345"
      }, {
        width: 16,
        height: 16,
           icon: require('../assets/24gf-phoneLoudspeaker.png'),
        num: 0,
        title: "12389"
      }, {
        width: 18,
        height: 17,
           icon: require('../assets/juchangxinxiang.png'),
        num: 0,
        title: "信访"
      }],
      dataLeftBottom: [
        {
        time: "2023-09-15 10:17:36",
        title: "局长信箱",
        type: "投诉类",
        content: "某某市居民朱某某自2019年起，屡次反映同一诉求，对其反映诉求，无为市相关部门已于2021年给予书面答复，但朱某某仍然屡次以访施压，并于2023...",
        name: "李四",
        phone: "17868003983",
        status: "1"
      },
      {
        time: "2023-09-15 10:17:36",
        title: "12345",
        type: "举报类",
        content: "某某市环保局、工业园区管委会对园区环境监管不力,排查环境污染隐患不深入,查处违法行为不及时,督促问题企业整改不够到位,致使园区违法排污...",
        name: "李四",
        phone: "17868003983",
        status: "2"
      },
      {
        time: "2023-09-15 10:17:36",
        title: "12389",
        type: "涉众类",
        content: "2023年以来,某某市某某区湘雅路街道成功化解一批时间长、难度大、涉及面广的“钉子案”,信访积案化解取得重大突破,基层治理成效明显...",
        name: "李四",
        phone: "17868003983",
        status: "1"
      },
      {
        time: "2023-09-15 10:17:36",
        title: "12345",
        type: "感谢类",
        content: "我市绿色太行国际公路自行车赛取得了圆满成功!在第二赛段比赛中，胜利完成了各项服务保障任务，叫响了'魅力太行，美在沙河”的口号，",
        name: "李四",
        phone: "17868003983",
        status: "1"
      }
      ],
      // , '望城分局', '长沙县局', '浏阳市局', '宁乡市局', '交警支队', '督察支队'
      BranchTypeList: ['芙蓉分局','芙蓉分局','岳麓分局','开福分局','雨花分局'],
      //  , 1680,888,777,656,554,423
      BranchTypeNum: [7864,6800,5240, 4168, 3200],
      RankingStatistics: [2500, 5000, 7500, 10000, 12500],
      dataList: ['2017', '2018', '2019', '2020', '2021', '2022', '2023'],
      productList: [
        { product: '芙蓉分局', '来件人数': 43, '化解人数': 85 },
        { product: '岳麓分局', '来件人数': 83, '化解人数': 73 },
        { product: '开福分局', '来件人数': 86, '化解人数': 65 },
        { product: '雨花分局', '来件人数': 74, '化解人数': 53 },
        { product: '长沙县局', '来件人数': 72, '化解人数': 53 },
        { product: '宁乡市局', '来件人数': 44, '化解人数': 59 },
        { product: '交警支队', '来件人数': 74, '化解人数': 59 },
        { product: '督察支队', '来件人数': 74, '化解人数': 59 }
      ],
      prodList: [
        { product: '督察支队', '督办件': 49, '已办结': 85 },
        { product: '雨花分局', '督办件': 81, '已办结': 73 },
        { product: '岳麓分局', '督办件': 84, '已办结': 65, },
        { product: '芙蓉分局', '督办件': 74, '已办结': 53 },
        { product: '开福分局', '督办件': 72, '已办结': 53 },
        { product: '长沙县局', '督办件': 84, '已办结': 59 },
        { product: '宁乡市局', '督办件': 74, '已办结': 59 },
        { product: '交警支队', '督办件': 94, '已办结': 59 }
      ],
      dataLists:[
        { value: 10042, name: '投诉类' },
        { value: 5120, name: '感谢类' },
        { value: 7120, name: '涉众类' },
        { value: 3394, name: '举报类' },
        { value: 2330, name: '建议类' },
        { value: 12652, name: '咨询求助类' },
       ],
       dataListss:[1800, 1240, 1168, 1200, 2336, 1680],
      incomingParts:[],
      Defuse:[],
      lineChart:[1000, 1223, 2345, 3764, 3456, 4567, 5578]
    }
  },
  created(){
       const end = new Date()
       const start = new Date()
       start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
       //近一个月的时间段
       console.log(this.newDate(start),this.newDate(end));
  },
  methods:{
       selectOpt(val) {
      console.log(val, 8888);
      this.BranchTypeNum = [];
      this.BranchTypeList = [];
      if (val == '全部') {
        this.BranchTypeList = ['芙蓉分局', '天心分局', '岳麓分局', '开福分局', '雨花分局'];
        //  , 1680,888,777,656,554,423
        this.BranchTypeNum = [7864, 6800, 5240, 4168, 3200,];
        this.echarts_1();
        return
      }

         this.BranchTypeList.push(val) ;

        this.BranchTypeNum.push(Math.ceil((Math.random()) * 2000));

      this.echarts_1();

    },
    selectOpt1(val){
      console.log(val);
         this.RankingStatistics = [];
       for (let i = 0; i < 7; i++) {
        this.RankingStatistics.push(Math.ceil(Math.random()*(i +2)))
      }
      console.log(this.RankingStatistics,11111);
      this.echarts_2();
    },
    selectOpt2(val){
      console.log(val,'val');
       this.dataList = [];
      if(val==1){
       this.dataList= ['2017', '2018', '2019', '2020', '2021', '2022', '2023'];
      }else if(val==2){
        this.dataList = ['1月', '2月', '3月', '4月', '5月', '5月', '6月'];
      }else{
         this.dataList = ['2023-01-15', '2023-02-15', '2023-03-15', '2023-04-15', '2023-05-15', '2023-06-15', '2023-07-15'];
      }
      this.echarts_2();

    },
    selectOpt3(val){
      this.RankingStatistics = [];
      for(let i =0;i<7;i++){
      this.RankingStatistics.push(Math.ceil(Math.random() * (i + 3)))
      }
      this.echarts_2();
    },
    //重复件化解情况
    resolveSituation(val){
      this.productList =[];
        if(val==0){
          this.productList=[
          { product: '芙蓉分局', '来件人数': 43, '化解人数': 85 },
          { product: '天心分局', '来件人数': 83, '化解人数': 73 },
          { product: '岳麓分局', '来件人数': 86, '化解人数': 65 },
          { product: '开福分局', '来件人数': 74, '化解人数': 53 },
          { product: '雨花分局', '来件人数': 72, '化解人数': 53 },
          { product: '高新分局', '来件人数': 44, '化解人数': 59 },
          { product: '望城分局', '来件人数': 74, '化解人数': 59 },
          { product: '长沙县局', '来件人数': 74, '化解人数': 59 }
        ]
        }else{
          let options= [{
          value: '0',
          label: '全部'
        }, {
          value: '1',
          label: '芙蓉分局'
        }, {
          value: '2',
          label: '天心分局'
        }, {
          value: '3',
          label: '岳麓分局'
        }, {
          value: '4',
          label: '开福分局'
        }, {
          value: '5',
          label: '雨花分局'
        }, {
          value: '6',
          label: '高新分局'
        }, {
          value: '7',
          label: '望城分局'
        }, {
          value: '8',
          label: '长沙县局'
        }, {
          value: '9',
          label: '浏阳市局'
        }, {
          value: '10',
          label: '宁乡市局'
        }, {
          value: '11',
          label: '交警支队'
        }, {
          value: '12',
          label: '督察支队'
        },
          ]
           this.productList.push({ product: options[val].label, '来件人数': Math.ceil(Math.random()*10), '化解人数': Math.ceil(Math.random() * 10) },)
        }
        this.echarts_3();
    },
    resolveSit(val){
      this.prodList =[];
        if(val==0){
          this.prodList= [
          { product: '督察支队', '督办件': 49, '已办结': 85 },
          { product: '雨花分局', '督办件': 81, '已办结': 73 },
          { product: '岳麓分局', '督办件': 84, '已办结': 65, },
          { product: '芙蓉分局', '督办件': 74, '已办结': 53 },
          { product: '开福分局', '督办件': 72, '已办结': 53 },
          { product: '长沙县局', '督办件': 84, '已办结': 59 },
          { product: '宁乡市局', '督办件': 74, '已办结': 59 },
          { product: '交警支队', '督办件': 94, '已办结': 59 }
        ]
        }else{
          let options = [{
          value: '0',
          label: '全部'
        }, {
          value: '1',
          label: '芙蓉分局'
        }, {
          value: '2',
          label: '天心分局'
        }, {
          value: '3',
          label: '岳麓分局'
        }, {
          value: '4',
          label: '开福分局'
        }, {
          value: '5',
          label: '雨花分局'
        }, {
          value: '6',
          label: '高新分局'
        }, {
          value: '7',
          label: '望城分局'
        }, {
          value: '8',
          label: '长沙县局'
        }, {
          value: '9',
          label: '浏阳市局'
        }, {
          value: '10',
          label: '宁乡市局'
        }, {
          value: '11',
          label: '交警支队'
        }, {
          value: '12',
          label: '督察支队'
        },
        ]
           this.prodList.push({ product: options[val].label, '督办件': Math.ceil(Math.random()*20), '已办结': Math.ceil(Math.random() * 20) },)
        }
        this.echarts_4();
    },
    changeTime(val){
      this.dataLists= [
        { value: Math.ceil(Math.random()*1000), name: '投诉类' },
        { value:  Math.ceil(Math.random()*5000), name: '感谢类' },
        { value:  Math.ceil(Math.random()*2000), name: '涉众类' },
        { value:  Math.ceil(Math.random()*10), name: '举报类' },
        { value:  Math.ceil(Math.random()* 100), name: '建议类' },
        { value:  Math.ceil(Math.random() * 3000), name: '咨询求助类' },
      ],
      this.dataListss= [Math.ceil(Math.random() * 1000),  Math.ceil(Math.random() * 1000),  Math.ceil(Math.random() * 1000),  Math.ceil(Math.random() * 1000),  Math.ceil(Math.random() * 1000),  Math.ceil(Math.random() * 1000)],
      this.echarts_5();
      this.echarts_6();

    },
    changeDate(){
      this.dataLists = [
        { value: Math.ceil(Math.random() * 1000), name: '投诉类' },
        { value: Math.ceil(Math.random() * 5000), name: '感谢类' },
        { value: Math.ceil(Math.random() * 2000), name: '涉众类' },
        { value: Math.ceil(Math.random() * 10), name: '举报类' },
        { value: Math.ceil(Math.random() * 100), name: '建议类' },
        { value: Math.ceil(Math.random() * 3000), name: '咨询求助类' },
      ],
        this.dataListss = [Math.ceil(Math.random() * 1000), Math.ceil(Math.random() * 1000), Math.ceil(Math.random() * 1000), Math.ceil(Math.random() * 1000), Math.ceil(Math.random() * 1000), Math.ceil(Math.random() * 1000)],
        this.echarts_5();
        this.echarts_6();
    },
    getweek() {//获取当天日期是周几
      var d = new Date();
      var weekday = new Array(7);
      weekday[0] = "星期日";
      weekday[1] = "星期一";
      weekday[2] = "星期二";
      weekday[3] = "星期三";
      weekday[4] = "星期四";
      weekday[5] = "星期五";
      weekday[6] = "星期六";
      var week = weekday[d.getDay()];
      return week;
    },
    //时间转化器
    newDate(time) {
      var date = new Date(time)
      var y = date.getFullYear()
      var m = date.getMonth() + 1
      m = m < 10 ? '0' + m : m
      var d = date.getDate()
      d = d < 10 ? '0' + d : d
      return y + '-' + m + '-' + d
    },
    //单位办件排名统计  时间筛选
   changDate(val){
    console.log(val,999);
   },

    echarts_1() {
  // 基于准备好的dom，初始化echarts实例
  var myChart = echarts.init(document.getElementById('echart1'));
  var lightBlue = {
    type: 'linear',
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [{
      offset: 0,
      color: 'rgba(41, 121, 255, 1)'
    }, {
      offset: 1,
      color: 'rgba(0, 192, 255, 1)'
    }],
    globalCoord: false
  }

  var option = {
    tooltip: {
      show: false
    },
    grid: {
      top: '10%',
      left: '100',
      right: '50',
      bottom: '0%',
    },
    xAxis: {
      min: 0,
      //max: 12000,
      splitLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      axisLine: {
        show: false

      },
      axisLabel: {
        show: false
      }
    },
    yAxis: {
      data: this.BranchTypeList,
      //offset: 15,
      axisTick: {
        show: false
      },
      axisLine: {
        show: false
      },
      axisLabel: {
        color: '#fff',
        fontSize: 15,


      }
    },
    series: [{
      type: 'bar',
      label: {
        show: true,
        zlevel: 10000,
        position: 'right',
        padding: 6,
        color: '#fff',
        fontSize: 16,
        formatter: '{c}'

      },
      itemStyle: {
        barBorderRadius: 25,
        color: new echarts.graphic.LinearGradient(
              0, 1, 0, 0,
              [{
                offset: 0,
                color: 'rgba(0, 204, 255, 1)'
              }, {
                offset: 1,
                color: 'rgba(42, 84, 180, 1)'
              }]
            )
      },
      barWidth: '18',

      data: this.BranchTypeNum,
      z: 5,
    }
    ],
  };


  // 使用刚指定的配置项和数据显示图表。
  myChart.setOption(option);
  window.addEventListener("resize", function () {
    myChart.resize();
  });
     },
     echarts_2(){
      var chartDom = document.getElementById('echart2');
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        color: 'rgba(135, 186, 248, 1)',
        textStyle: {
          fontWeight: 'normal',              //标题颜色
          color: 'rgba(135, 186, 248, 1)'
        },
        xAxis: {
          type: 'category',
          data: this.dataList,
            axisTick: {
            show: false
          },
          axisLine: {
            show: true,
             lineStyle: {
              color: 'rgba(1, 47, 101, 1)',
              width: 1,
              type: "solid"
            }
          },

        },
        yAxis: {
          type: 'value',
          axisTick:{
            show:false
          },
          axisLine: {
            show: false
          },
             splitLine: {
            show: true,
            lineStyle: {
              color: 'rgba(1, 47, 101, 1)',
              width: 1,
              type: "solid"
            }
          }
        },
        series: [
          {
            data: this.RankingStatistics,
            type: 'bar',
            barWidth: '20%',
            showBackground: true,
            backgroundStyle: {
               color: 'rgba(42, 174, 250, 0.2)'
            }
          }
        ]
      };

      option && myChart.setOption(option);
     },
     echarts_3(){
      var app = {};
      var chartDom = document.getElementById('echart3');
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        textStyle: {
          fontWeight: 'normal',              //标题颜色
          color: 'rgba(135, 186, 248, 1)'
        },
        legend: {
          top: '1',
          left: '78%',
          textStyle: {
            color: '#fff',
            fontWeight: 'normal',
            fontSize: 14
          }
          // data: ['警情', '信访', '12389']
        },
        tooltip: {},
        dataset: {
          dimensions: ['product', '来件人数', '化解人数'],
          source: this.productList
        },
        xAxis: {
          type: 'category',
           axisTick: {
            show: false
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: 'rgba(1, 47, 101, 1)',
              width: 1,
              type: "solid"
            }
          },
       },
        yAxis: {
          // axisLabel: {
          //   formatter: "{value}"
          // }
            axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: 'rgba(1, 47, 101, 1)',
              width: 1,
              type: "solid"
            }
          }
        },
        // Declare several bar series, each will be mapped
        // to a column of dataset.source by default.
        series: [
          {
            type: 'bar',
            barWidth: '8%',
               barGap: 1, //柱子之间间距
            itemStyle: {
              normal: {
               label: {
                  formatter: this.incomingParts,
                  show: true,
                  position: "top",
                  textStyle: {
                    fontWeight: "bolder",
                    fontSize: "12",
                    color: "#fff"
                  }
                },
                //这里是颜色
                color: new echarts.graphic.LinearGradient(
                  0, 1, 0, 0,
                  [{
                    offset: 0,
                    color: 'rgba(19, 100, 255, 1)'
                  }, {
                    offset: 1,
                    color: 'rgba(119, 156, 255, 1)'
                  }]
                )
              }
            },
        } ,
          {
            type: 'bar',
            barWidth: '8%',
             barGap: 1, //柱子之间间距
            itemStyle: {
              normal: {
               label: {
                  formatter: this.Defuse,
                  show: true,
                  position: "top",
                  textStyle: {
                    fontWeight: "bolder",
                    fontSize: "12",
                    color: "#fff"
                  }
                },
                //这里是颜色
                color: new echarts.graphic.LinearGradient(
                  0, 1, 0, 0,
                  [{
                    offset: 0,
                    color: 'rgba(255, 86, 86, 1)'
                  }, {
                    offset: 1,
                    color: 'rgba(254, 153, 72, 1)'
                  }]
                )
              }
            },
          }
        ]
      };

      option && myChart.setOption(option);

     },
     echarts_4(){
      var app = {};
      var chartDom = document.getElementById('echart4');
      var myChart = echarts.init(chartDom);
      var option;
   option = {
        textStyle: {
          fontWeight: 'normal',              //标题颜色
          color: 'rgba(135, 186, 248, 1)'
        },
         legend: {
          top: '1',
          left: '78%',
          textStyle: {
            color: '#fff',
            fontWeight: 'normal',
            fontSize: 14
          }
          // data: ['警情', '信访', '12389']
        },

        tooltip: {},
        dataset: {
          dimensions: ['product', {name:'督办件',left:'30%',top:'5%'}, { name: '已办结', left: '40%', top: '5%' }],
          source: this.prodList
        },
        xAxis: {
          type: 'category',
          axisTick: {
            show: false
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: 'rgba(1, 47, 101, 1)',
              width: 1,
              type: "solid"
            }
          },
        },
        yAxis: {
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: 'rgba(1, 47, 101, 1)',
              width: 1,
              type: "solid"
            }
          }
        },
        // Declare several bar series, each will be mapped
        // to a column of dataset.source by default.
        series: [
          {
           type: 'bar',
           barWidth: '8%',
           barGap: 1, //柱子之间间距
            itemStyle: {
              normal: {
                label: {
                  formatter: this.incomingParts,
                  show: true,
                  position: "top",
                  textStyle: {
                    fontWeight: "bolder",
                    fontSize: "12",
                    color: "#fff"
                  }
                },
                //这里是颜色
                   //这里是颜色
                color: new echarts.graphic.LinearGradient(
                  0, 1, 0, 0,
                  [{
                    offset: 0,
                    color: 'rgba(170, 128, 2, 1)'
                  }, {
                    offset: 1,
                    color: 'rgba(247, 227, 3, 1)'
                  }]
                )
              }
            },
         },
         { type: 'bar',
           barWidth: '8%',
           barGap: 1, //柱子之间间距
           itemStyle: {
              normal: {
                label: {
                  formatter: this.Defuse,
                  show: true,
                  position: "top",
                  textStyle: {
                    fontWeight: "bolder",
                    fontSize: "12",
                    color: "#fff"
                  }
                },
                //这里是颜色
                  //这里是颜色
                color: new echarts.graphic.LinearGradient(
                  0, 1, 0, 0,
                  [{
                    offset: 0,
                    color: 'rgba(2, 140, 145, 1)'
                  }, {
                    offset: 1,
                    color: 'rgba(0, 228, 255, 1)'
                  }]
                )
              }
          },
      }]
      };

      option && myChart.setOption(option);

     },
     echarts_5() {
      var chartDom = document.getElementById('echart5');
      var myChart = echarts.init(chartDom);
      var option;
        option = {
          tooltip: {
            trigger: 'item',
            formatter: "{a} <br/>{b} : {c} ({d}%)"
          },
          color: ['#8fc31f', '#f35833', '#00ccff', '#ffcc00', '#f5e965', '#a74faf', '#ff9668'],
          series: [
            {
              name: '资源总量构成',
              type: 'pie',
              radius: '57%',
              center: ['50%', '37%'],
              data: this.dataLists,
              itemStyle: {
                emphasis: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                },
                normal: {
                    label: {
                      show: true,
                      //	                            position:'inside',
                      formatter: '{b} : {c} ({d}%)',
                      fontSize: 10,    //文字的字体大小
                    }
                },
               labelLine: { show: true }

              },
            }
          ]
        };
        // 使用刚指定的配置项和数据显示图表。
        option && myChart.setOption(option);
      },
    echarts_6() {
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById('echart6'));

      var lightBlue = {
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0,
        y2: 1,
        colorStops: [{
          offset: 0,
          color: 'rgba(41, 121, 255, 1)'
        }, {
          offset: 1,
          color: 'rgba(0, 192, 255, 1)'
        }],
        globalCoord: false
      }

      var option = {
        tooltip: {
          show: false
        },
        grid: {
          top: '0%',
          left: '110',
          right: '50',
          bottom: '0%',
        },
        xAxis: {
          min: 0,
          //max: 12000,
          splitLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: false

          },
          axisLabel: {
            show: false
          }
        },
        yAxis: {

          data: ['咨询求助类', '投诉类', '感谢类', '涉众类', '举报类', '建议类'],
          //offset: 15,
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            color: '#FFF',
            fontSize: 14,


          }
        },
        series: [{
          type: 'bar',
          label: {
            show: true,
            zlevel: 10000,
            position: 'right',
            padding: 6,
            color: '#fff',
            fontSize: 14,
            formatter: '{c}'

          },
          itemStyle: {
            barBorderRadius: 25,
                 color: new echarts.graphic.LinearGradient(
              0, 1, 0, 0,
              [{
                offset: 0,
                color: 'rgba(254, 150, 72, 1)'
              }, {
                offset: 1,
                color: 'rgba(255, 100, 83, 1)'
              }]
            )
          },
          barWidth: '15',

          data: this.dataListss,
          z: 6
        }
        ],
      };


      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    echarts_7(){
        var chartDom = document.getElementById('echart7');
        var myChart = echarts.init(chartDom);
        var option;
        option = {
          xAxis: {
            type: 'category',
            data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月','8月','9月'],
            axisTick: {
            show: false
          },
          axisLine: {
            show: true,
             color: '#FFF',
          },
          axisLabel: {
            color: '#FFF',
            fontSize: 14,
          },
          // splitLine: {
          //   show: false
          // }
          },
          yAxis: {
            type: 'value',
            axisTick: {
            show: false
            },
            axisLine: {
              show: false
            },
            axisLabel: {
              color: '#FFF',
              fontSize: 14,
            },
            splitLine:{
              show: false
            }

          },
          series: [
            {
              data: this.lineChart,
              type: 'line',
              smooth: true,
              lineStyle:{
                color: 'rgba(67, 154, 255, 1)'
              },
               itemStyle: {
                //下面是拐点样式配置属性
                color: '#fff',//这里设置的拐点颜色
                borderColor: "rgba(67, 154, 255, 1)", //  拐点边框颜色
                borderWidth: 1, //  拐点边框宽度
                shadowColor: "#F5F5F5", //  阴影颜色
                shadowBlur: 4, //  阴影渐变范围控制
            },
            symbol: "circle", //拐点的形状
            symbolSize: 10,//拐点大小
            }
          ]
        };

      option && myChart.setOption(option);
       },
    echarts_8() {
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById('echart8'));

      var lightBlue = {
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0,
        y2: 1,
        colorStops: [{
          offset: 0,
          color: 'rgba(41, 121, 255, 1)'
        }, {
          offset: 1,
          color: 'rgba(0, 192, 255, 1)'
        }],
        globalCoord: false
      }

      var option = {
        tooltip: {
          show: false
        },
        grid: {
          top: '0%',
          left: '200',
          right: '50',
          bottom: '0%',
        },
        xAxis: {
          min: 0,
          //max: 12000,
          splitLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            show: false
          }
        },
        yAxis: {
          data: ['信箱&警情（10次以上报警）', '信箱&信访', '信箱&12389'],
          //offset: 15,
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            color: '#fff',
            fontSize: 14,
          }
        },
        series: [{
          type: 'bar',
          label: {
            show: true,
            zlevel: 10000,
            position: 'right',
            padding: 6,
            color: '#fff',
            fontSize: 14,
            formatter: '{c}'

          },
          itemStyle: {
            barBorderRadius: 25,
            color: new echarts.graphic.LinearGradient(
              0, 1, 0, 0,
              [{
                offset: 0,
                color: 'rgba(119, 151, 255, 1)'
              }, {
                offset: 1,
                color: 'rgba(21, 0, 222, 1)'
              }]
            )
          },
          barWidth: '15',
          data: [3543, 6543, 2435],
          z: 6
        }
        ],
      };


      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    echarts_9(){
      var chartDom = document.getElementById('echart9');
      var myChart = echarts.init(chartDom);
      var option;
      var datas = [
        [
          { name: '警情', value: 3545 },
          { name: '12389', value: 2435 },
          { name: '信访', value: 6543 },
        ]
      ];
      option = {
        title: {
          text: '',
          left: 'center',
          textStyle: {
            color: '#fff',
            fontWeight: 'normal',
            fontSize: 24
          }
        },

          legend: {
          top: '-5',
          left: 'center',
            textStyle: {
            color: '#fff',
            fontWeight: 'normal',
            fontSize: 14
          }
          // data: ['警情', '信访', '12389']
        },
        series: datas.map(function (data, idx) {
          var top = idx * 33.3;
          return {
            type: 'pie',
            radius: [20, 80],
            top: 25 + '%',
            height: '33.33%',
            left: 'center',
            width: 400,
            itemStyle: {
              borderColor: '#fff',
              borderWidth: 0
            },
            label: {
              alignTo: 'edge',
              formatter: '{name|{b}}\n{time|{c}}',
              minMargin: 5,
              edgeDistance: 10,
              lineHeight: 15,
               color: '#fff',
              rich: {
                time: {
                  fontSize: 10,
                  color: '#999'
                }
              }
            },
            labelLine: {
              length: 15,
              length2: 0,
              maxSurfaceAngle: 80
            },
            labelLayout: function (params) {
              const isLeft = params.labelRect.x < myChart.getWidth() / 2;
              const points = params.labelLinePoints;
              // Update the end point.
              points[2][0] = isLeft
                ? params.labelRect.x
                : params.labelRect.x + params.labelRect.width;
              return {
                labelLinePoints: points
              };
            },
            data: data
          };
        })
      };

      option && myChart.setOption(option);
    },
    changeList(){
      this.lineChart=[Math.ceil(Math.random()*1000), Math.ceil(Math.random() * 1000), Math.ceil(Math.random() * 1000), Math.ceil(Math.random() * 1000), Math.ceil(Math.random() * 1000), Math.ceil(Math.random() * 1000), Math.ceil(Math.random() * 1000)]
      this.echarts_7();
    },
    changeSelts(){
      this.lineChart = [Math.ceil(Math.random() * 1000), Math.ceil(Math.random() * 1000), Math.ceil(Math.random() * 1000), Math.ceil(Math.random() * 1000), Math.ceil(Math.random() * 1000), Math.ceil(Math.random() * 1000), Math.ceil(Math.random() * 1000)]
      this.echarts_7();
    },
    changeSeltss(){
      this.lineChart = [Math.ceil(Math.random() * 1000), Math.ceil(Math.random() * 1000), Math.ceil(Math.random() * 1000), Math.ceil(Math.random() * 1000), Math.ceil(Math.random() * 1000), Math.ceil(Math.random() * 1000), Math.ceil(Math.random() * 1000)]
      this.echarts_7();
    },
    changeSeltss(){
      this.lineChart = [Math.ceil(Math.random() * 1000), Math.ceil(Math.random() * 1000), Math.ceil(Math.random() * 1000), Math.ceil(Math.random() * 1000), Math.ceil(Math.random() * 1000), Math.ceil(Math.random() * 1000), Math.ceil(Math.random() * 1000)]
      this.echarts_7();
    },
    changeSeltsss(){
      this.lineChart = [Math.ceil(Math.random() * 1000), Math.ceil(Math.random() * 1000), Math.ceil(Math.random() * 1000), Math.ceil(Math.random() * 1000), Math.ceil(Math.random() * 1000), Math.ceil(Math.random() * 1000), Math.ceil(Math.random() * 1000)]
      this.echarts_7();
    },
  },
  mounted () {
    // let url = './utils.json';
    // axios.get('./utils.json').then((response) =>{
    //   //请求成功回调函数
    //   console.log(response.data);
    // }).catch((error)=> {
    //   //请求失败回调函数
    //   console.log(error);
    // });
    this.echarts_1()
    this.echarts_2()
    this.echarts_3()
    this.echarts_4()
    this.echarts_5()
    this.echarts_6()
    this.echarts_7()
    this.echarts_8()
    this.echarts_9()


    const timerContainer = document.getElementById('timer');
    // 定义计时器变量
    let seconds = new Date().getSeconds();
    let minutes = new Date().getMinutes();
    let hours = new Date().getHours();
    // 更新计时器显示
    function updateTimer() {
      seconds++;
      if (seconds === 60) {
        seconds = 0;
        minutes++;
      }
      if (minutes === 60) {
        minutes = 0;
        hours++;
      }
      // 格式化时间
      const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
      // 更新计时器显示
      timerContainer.innerText = formattedTime;
    }
    // 启动计时器
    setInterval(updateTimer, 1000);
  }
}
</script>

<style lang="scss">

.el-input--suffix .el-input__inner,.el-range-editor .el-range-input {
  color:white;
}
.selts .el-input--suffix .el-input__inner{

  width:120px;
}
.selt .el-input--suffix .el-input__inner{
  border: 0;
  width:30px
}
.el-range-editor .el-range-input,.el-input--suffix .el-input__inner {
  background-color: transparent;
  border: 1px solid #17499D;
}

*{
  padding: 0;
  margin: 0;
}
  .fontSize{
    font-size: 30px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #0096FF;
    line-height: 43px;
    background: linear-gradient(92deg, #0072FF 0%, #00EAFF 48.8525390625%, #01AAFF 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
 .home{
    width:3840px;
    height: 1080px;
    // height: 100%;
    padding-bottom:20px ;
    background-color: #000533;
    .header {
          width: 3840px;
          height: 96px;
          background-image: url('../assets/headright@2x.png');
          background-size: 100% 100%;
          position: relative;
          .time{
            color:#39D6FE;
            position: absolute;
            right: 916px;
            top: 5px;
          }
    }
    .main{
        display: flex;
        justify-content: space-around;
        flex-shrink:1;
         margin: 10px 15px;
        .left_one{
          width: 460px;
          height: 952px;
          background-image: url('../assets/one@2x.png');
           background-size: cover;
        }
        .left_two{
          .left_two_msg{
            box-sizing: border-box;
            padding: 0 6px;
            margin-bottom: 6px;
          position:relative;
          width: 780px;
          height: 210px;
          background-image: url('../assets/left_two_msg.png');
           background-size: cover;

          }
          .left_two_real{
          position:relative;
          box-sizing: border-box;
          padding: 10px;
          padding-top: 0px;
          width: 780px;
          height: 732px;
          background-image: url('../assets/57@2x(3).png');
          background-size: cover;
          }

        }
        .center{
          .center_content{
            position:relative;
            width:1671px;
            height:383px;
            background-image: url('../assets/center_top.png');
            background-size: cover;
            .center_top{
                   display: flex;
                  justify-content: center;

            }
          }
          .center_content_center{
             position:relative;
            width:1671px;
            height:277px;
            background-image: url('../assets/center_bottom.png');
            background-size: cover;
          }
        }
        .right{
           .right_top{
            position:relative;
            width:849px;
            height:390px;
            background-image: url('../assets/right_top.png');
            background-size: cover;
           }
           .right_center{
               position:relative;
            width:849px;
            height:301px;
            background-image: url('../assets/right_center.png');
            background-size: cover;
           }
           .right_bottom{
               position:relative;
            width:849px;
            height:248px;
            background-image: url('../assets/right_bottom.png');
            background-size: cover;
           }
        }

    }
 }

</style>
