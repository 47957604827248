import axios from 'axios'
// import qs from 'qs'


// 创建axios实例
const service = axios.create({
    baseURL: 'http://192.168.2.135:8999/api', // api的base_url
    timeout: 120000, // 请求超时时间
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json'
    }
})

let loadingLayer = '' // loading加载层
    // request拦截器

service.interceptors.request.use(
    (config) => {
        config.timeout = 60000 * 2

        // 是否要更改header内容
        if (config.isFile) {
            config.headers['Content-Type'] = 'multipart/form-data'
        } else if (config.isJson) {
            config.headers['Content-Type'] = 'application/json'
        }

        if (!config.data) {
            // 防止不传参数的情况下，config中没有data属性
            config['data'] = {}
        }

        if (config.params) {
            config.data = {...config.data, ...config.params }
        }

        if (config.method === 'post' || config.method === 'put') {
            // 设置参数拼接方式
            if (
                config.data &&
                config.headers['Content-Type'] === 'application/x-www-form-urlencoded' &&
                config.json
            ) {
                // config.data = qs.stringify(config.data, { arrayFormat: 'indices', allowDots: true })
            } else if (
                config.data &&
                config.headers['Content-Type'] === 'application/x-www-form-urlencoded'
            ) {
                // config.data = qs.stringify(config.data, { arrayFormat: 'indices', allowDots: true })
            }
        } else {
            if (config.data) {
                // config.url = config.json
                //   ? config.url +
                //     '?' +
                // qs.stringify(config.data, { arrayFormat: 'indices', allowDots: true })
                // : config.url + '?' + qs.stringify(config.data)
            }
        }

        return config
    },
    (error) => {
        // Do something with request error
        console.log(error) // for debug
        Promise.reject(error)
    }
)

// respone拦截器
service.interceptors.response.use(
    (response) => {
        if (response.status === 200) {
            const res = response.data

            if (res.code !== '0' && res.code !== '0000') {
                let message
                if (
                    res.subErrors &&
                    res.subErrors.length !== 0 &&
                    res.subErrors[0].message &&
                    res.subErrors[0].message.length !== 0 &&
                    res.subErrors[0].code
                ) {
                    // 判断错误信息
                    if (res.subErrors[0].code.indexOf('isv.missing-parameter') >= 0) {
                        message = res.subErrors[0].message
                    } else if (res.subErrors[0].code.indexOf('isv.invalid-paramete') >= 0) {
                        message = res.subErrors[0].message
                    } else if (res.subErrors[0].code.indexOf('isv.parameters-mismatch') >= 0) {
                        message = res.subErrors[0].message
                    } else if (
                        res.subErrors[0].code.indexOf('isv.base-client-api-error:OBJECT_NOT_FOUND') >= 0
                    ) {
                        message = '任务已办理或已撤回'
                    } else {
                        message = res.subErrors[0].message
                    }
                } else {
                    message = res.msg
                }
            }
            return res
        } else {
            return Promise.reject(isEn ? 'Network error' : '网络请求出错')
        }
    },
    (error) => {
        console.log('err' + error) // for debug


        // 处理网络异常，重新请求
        var config = error.config
            // If config does not exist or the retry option is not set, reject
        if (!config || !config.retry) return Promise.reject(error)
            // Set the variable for keeping track of the retry count
        config.__retryCount = config.__retryCount || 0
            // Check if we've maxed out the total number of retries
        if (config.__retryCount >= config.retry) {
            // Reject with the error
            return Promise.reject(error)
        }

        // Increase the retry count
        config.__retryCount += 1
            // Create new promise to handle exponential backoff
        var backoff = new Promise(function(resolve) {
                setTimeout(function() {
                    resolve()
                }, config.retryDelay || 1)
            })
            // Return the promise in which recalls axios to retry the request
        return backoff.then(function() {
            return axios(config)
        })
    }
)


export default service